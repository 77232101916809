<template>
  <div class="accomodation-body mb-10">
    <div class="room-types">
      <div class="card elevation-2">
        <img src="/image13.jpg" alt="" class="room-image" />
        <div class="px-5 pb-5 d-flex justify-space-between align-center">
          <p class="mt-5 title">Non AC Rooms</p>
          <button class="book-btn" @click="redirectBookingPage">Book</button>
        </div>
      </div>
      <div class="card elevation-2">
        <img src="/image14.jpg" alt="" class="room-image" />
        <div class="px-5 pb-5 d-flex justify-space-between align-center">
          <p class="mt-5 title">AC Double Bed</p>
          <button class="book-btn" @click="redirectBookingPage">Book</button>
        </div>
      </div>
      <div class="card elevation-2">
        <img src="/image7.jpg" alt="" class="room-image" />
        <div class="px-5 pb-5 d-flex justify-space-between align-center">
          <p class="mt-5 title">Non AC Triple Bed</p>
          <button class="book-btn" @click="redirectBookingPage">Book</button>
        </div>
      </div>
    </div>
    <div class="text-center">
      <button class="view-all" @click="showAllRooms">View All Rooms</button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    showAllRooms() {
      this.$router.push('/rooms');
    },
    redirectBookingPage() {
      this.$router.push('/book');
    },
  },
};
</script>

<style scoped>
.room-types {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
}
.room-image {
  width: 100%;
  max-height: 220px;
}
.card {
  border-radius: 5px;
  cursor: pointer;
}
.card:hover {
  background-color: #eee;
  transform: scale(1.05);
  transition: all 0.6s ease;
}
.view-all {
  background: #563610;
  padding: 8px 20px;
  color: white;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 20px;
  border-radius: 8px;
}
.book-btn {
  background: #563610;
  padding: 8px 20px;
  color: white;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 8px;
}
@media (max-width: 900px) {
  .room-types {
    grid-template-columns: 100%;
  }
  .room-image {
    max-height: 80%;
  }
}
</style>
