<template>
  <div class="about-us-body custom-padding">
    <h1 class="my-1 section-header text-center">About Us</h1>
    <About />
  </div>
</template>

<script>
import About from '@/components/About.vue';
export default {
  components: {
    About,
  },
};
</script>

<style scoped>
.custom-padding {
  padding: 50px;
}
.section-header {
  font-size: 50px;
  letter-spacing: 1px;
  font-style: italic;
  font-weight: 300;
}
@media (max-width: 768px) {
  .custom-padding {
    padding: 20px;
  }
}
.about-us-body {
  transform: translate(-1000px);
  animation: slidein 0.5s linear 0s 1 forwards;
}
@keyframes slidein {
  to {
    transform: translate(0);
  }
}
</style>
