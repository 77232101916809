<template>
  <div id="footer-body">
    <section class="contact">
      <h2>Contact</h2>
      <p>
        <v-icon color="#fff" small>mdi-map-marker</v-icon>
        1, Daresi No 1, Agra Fort, Railway Station, Agra, Uttar Pradesh 282003
      </p>
      <p>
        <v-icon color="#fff" small>mdi-email-open</v-icon>
        ajayint2012@gmail.com
      </p>
      <p>
        <v-icon color="#fff" small>mdi-phone</v-icon>
        +91-9897477602
      </p>
      <p>
        <v-icon color="#fff" small>mdi-phone</v-icon>
        +91-9837160369
      </p>
      <p>
        <v-icon color="#fff" small>mdi-phone</v-icon>
        0562-2251076 / 4006507
      </p>
    </section>
    <section>
      <h2>Property</h2>
      <p @click="reRoute('about')" class="link">About Us</p>
      <p @click="reRoute('rooms')" class="link">Accomodation</p>
      <p @click="reRoute('offers')" class="link">Offers</p>
    </section>
    <section>
      <h2>Policies</h2>
      <p @click="reRoute('policies')" class="link">Hotel Policies</p>
      <p @click="reRoute('terms')" class="link">Terms & Conditions</p>
      <p @click="reRoute('policy/privacy')" class="link">Privacy Policies</p>
      <p @click="reRoute('policy/cancellation')" class="link">
        Cancellation & Refund Policies
      </p>
    </section>
  </div>
</template>

<script>
export default {
  methods: {
    reRoute(param) {
      this.$router.push(`/${param}`);
    },
  },
};
</script>

<style scoped>
p {
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 300;
}
p.link {
  cursor: pointer;
}
#footer-body {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  align-items: flex-start;
  padding: 10px;
  max-width: 1200px;
  margin: auto;
}
h2 {
  font-weight: 300;
  margin-bottom: 10px;
}
@media (max-width: 1200px) {
  #footer-body {
    max-width: 90vw;
  }
}
@media (max-width: 850px) {
  #footer-body {
    grid-gap: 1rem;
  }
  p {
    font-size: 13px;
  }
}
@media (max-width: 600px) {
  #footer-body {
    grid-template-columns: 100%;
  }
}
</style>
