<template>
  <div class="privacy-body">
    <h1 class="text-center section-header mb-5">Privacy Policy</h1>
    <p>
      This Privacy Policy governs the manner in which Hotel Ajay International
      collects, uses, maintains and discloses information collected from users
      (each, a "User") of the www.hotelajayinternational.in website ("Site").
      This privacy policy applies to the Site and all products and services
      offered by Hotel Ajay International.
    </p>

    <h6 class="mt-10 title">
      Personal Identification Information
    </h6>

    <p>
      We may collect personal identification information from Users in a variety
      of ways, including, but not limited to, when and how Users visit our site,
      fill out a form, and in connection with other activities, services,
      features or resources we make available on our Site. Users may be asked
      for, as appropriate, name, email address, mailing address, phone number,
      and other information. Users may, however, visit our Site anonymously. We
      will collect personal identification information from Users only if they
      voluntarily submit such information to us (filling out an enquiry form).
      Users can always refuse to supply personally identification information,
      except that it may prevent them from engaging in certain Site related
      activities.
    </p>

    <h6 class="mt-10 title">
      Non-personal Identification Information
    </h6>

    <p>
      We may collect non-personal identification information about Users
      whenever they interact with our Site. Non-personal identification
      information may include the browser name, user IP and technical
      information about Users means of connection to our Site, such as the
      operating system and the Internet service providers utilized and other
      similar information.
    </p>

    <h6 class="mt-10 title">
      Web browser cookies
    </h6>

    <p>
      Our Site may use "cookies" to enhance User experience. User's web browser
      places cookies on their hard drive for record-keeping purposes and
      sometimes to track information about them. User may choose to set their
      web browser to refuse cookies, or to alert you when cookies are being
      sent. If they do so, note that some parts of the Site may not function
      properly.
    </p>

    <h6 class="mt-10 title">
      How we use collected information
    </h6>

    <p class="mt-5">
      Hotel Ajay International may collect and use Users personal information
      for the following purposes:
    </p>
    <ul>
      <li>
        <p>
          To improve customer service
          <span>
            Information you provide helps us respond to your customer service
            requests and support needs more efficiently.
          </span>
        </p>
      </li>
      <li>
        <p>
          To personalize user experience
          <span>
            We may use information in the aggregate to understand how our Users
            as a group use the services and resources provided on our Site.
          </span>
        </p>
      </li>
      <li>
        <p>
          To improve our Site
          <span>
            We may use feedback you provide to improve our products and
            services.
          </span>
        </p>
      </li>
      <li>
        <p>
          To process payments
          <span>
            We may use the information Users provide about themselves when
            placing an order only to provide service to that order. We do not
            share this information with outside parties except to the extent
            necessary to provide the service.
          </span>
        </p>
      </li>
    </ul>

    <h6 class="mt-10 title">
      Sharing your personal information
    </h6>

    <p>
      We do not sell, trade, or rent Users personal identification information
      to others. We may use third party service providers to help us operate our
      business and the Site or administer activities on our behalf. We may share
      your information with these third parties for those limited purposes
      provided that you have given us your permission.
    </p>

    <h6 class="mt-10 title">
      Changes to this privacy policy
    </h6>

    <p>
      Hotel Ajay International has the discretion to update this privacy policy
      at any time. When we do, we will revise the updated date at the bottom of
      this page. We encourage Users to frequently check this page for any
      changes to stay informed about how we are helping to protect the personal
      information we collect. You acknowledge and agree that it is your
      responsibility to review this privacy policy periodically and become aware
      of modifications.
    </p>

    <h6 class="mt-10 title">
      Your acceptance of these terms
    </h6>

    <p>
      By using this Site, you signify your acceptance of this policy. If you do
      not agree to this policy, please do not use our Site. Your continued use
      of the Site following the posting of changes to this policy will be deemed
      your acceptance of those changes. You can always write to us in the below
      contact details
    </p>

    <h6 class="mt-10 title">
      Contacting us
    </h6>

    <p>
      If you have any questions about this Privacy Policy, the practices of this
      site, or your dealings with this site, please contact or mail us at:
    </p>
    <p>
      ajayint2012@gmail.com | +91-9897477602 | +91-9837160369 | 0562-2251076 /
      4006507
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
p {
  text-align: justify;
}
.section-header {
  font-size: 50px;
  letter-spacing: 1px;
  font-style: italic;
  font-weight: 300;
}
.privacy-body {
  transform: translate(-1000px);
  animation: slidein 0.5s linear 0s 1 forwards;
  padding: 50px;
}
h6.title {
  font-weight: 400;
  font-size: 22px !important;
  margin-bottom: 10px;
  text-transform: uppercase;
}
@keyframes slidein {
  to {
    transform: translate(0);
  }
}
@media (max-width: 600px) {
  .privacy-body {
    padding: 20px;
  }
}
</style>
