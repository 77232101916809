<template>
  <div class="home-container">
    <img src="/image1.jpg" alt="" class="homepage-img" />
    <v-container class="pa-5">
      <About />
      <h1 class="mt-10 display-1">Rooms & Accomodations</h1>
      <Accomodation class="mt-5" />
      <Nearby class="mt-15" />
    </v-container>

    <footer>
      <Map />
    </footer>
  </div>
</template>

<script>
import About from '@/components/About.vue';
import Map from '@/components/UI/Map.vue';
import Accomodation from '@/components/Accomodation.vue';
import Nearby from '@/components/Nearby.vue';
export default {
  name: 'Home',
  components: { About, Map, Accomodation, Nearby },
  data() {
    return {
      showOffer: false,
    };
  },
};
</script>

<style scoped>
.homepage-img {
  width: 100%;
  height: auto;
}

.constraint-height {
  margin-top: 50px;
  margin-bottom: 50px;
}

@media (max-width: 768px) {
  .home-body {
    min-height: 0;
    height: 50vh;
  }
  .constraint-height {
    margin-top: 0;
    margin-bottom: 0;
  }
}
</style>
