<template>
  <div id="offer-body" class="text-center elevation-10">
    <div class="d-flex align-center justify-space-between mb-2">
      <p class="title">Limited time offer.</p>
      <v-icon color="#ccc" @click="handleClose">mdi-close-thick</v-icon>
    </div>
    <p>Book now and get <span class="font-bold">free breakfast</span> .</p>
    <v-btn outlined small color="success" @click="redirectBooking">Book</v-btn>
  </div>
</template>

<script>
export default {
  methods: {
    redirectBooking() {
      this.$router.push('/book');
      this.$emit('close');
    },
    handleClose() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
#offer-body {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 100;
  background: #fff;
  min-width: 300px;
  padding: 20px;
  border-radius: 10px;
  max-height: 175px;
}
.title {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0;
}
.font-bold {
  font-weight: bold;
}
@media (max-width: 600px) {
  #offer-body {
    top: 70%;
    left: 50%;
    transform: translate(-50%, -70%);
  }
}
</style>
