<template>
  <div class="backdrop">
    <div class="content pa-5">
      <div class="close-icon">
        <v-icon color="white" large @click="closeMobileNavbar"
          >mdi-close</v-icon
        >
      </div>
      <h1 class="links" @click="redirectRoute(1)">Home</h1>
      <h1 class="links" @click="redirectRoute(4)">Book</h1>
      <h1 class="links" @click="redirectRoute(5)">About Us</h1>
      <h1 class="links" @click="redirectRoute(2)">Rooms</h1>
      <h1 class="links" @click="redirectRoute(3)">Offers</h1>
      <h1 class="links" @click="redirectRoute(6)">Privacy Policy</h1>
      <h1 class="links" @click="redirectRoute(7)">Hotel Policy</h1>
      <h1 class="links" @click="redirectRoute(8)">Cancellation Policy</h1>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    closeMobileNavbar() {
      this.$store.dispatch('showMobileNavbar', false);
    },
    redirectRoute(value) {
      this.closeMobileNavbar();
      switch (+value) {
        case 1:
          this.$router.push('/');
          break;
        case 2:
          this.$router.push('/rooms');
          break;
        case 3:
          this.$router.push('/offers');
          break;
        case 4:
          this.$router.push('/book');
          break;
        case 5:
          this.$router.push('/about');
          break;
        case 6:
          this.$router.push('/policy/privacy');
          break;
        case 7:
          this.$router.push('/policies');
          break;

        case 8:
          this.$router.push('/policy/cancellation');
          break;
      }
    },
  },
};
</script>

<style scoped>
.booking-link {
  color: white;
  text-decoration: none;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10;
  height: 0vh;
  animation: slideDown 0.3s ease-in 0s 1 forwards;
}
@keyframes slideDown {
  to {
    height: 100vh;
  }
}
.content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  z-index: 11;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
  width: 100vw;
}
.close-icon {
  position: absolute;
  top: 25px;
  right: 15px;
}
h1.links {
  font-size: 25px;
}
</style>
