<template>
  <div id="roomlist" class="text-center">
    <div class="content">
      <h3 class="section-header pt-5">Our Image Gallery</h3>
      <p class="font-italic pa-5 text-justify">
        Hotel Ajay International, is situated at a distance of 3 km from Taj
        Mahal . The hotel offers free Wi-Fi facility, maintains 2 floors and 19
        rooms. Some of the rooms are equipped with amenities like seating area,
        television, air conditioner, wardrobe, bottled drinking water and
        attached bathroom with hot and cold water facility.The hotel also
        provides laundry, medical and 24-hour room service. Additional
        facilities include luggage storage, business centre and power backup
        generator. Hotel Ajay International has a roof-top restaurant within its
        premises. Guests can visit Shri Mankameshwar Mandir (0.1 km) and Agra
        Fort (1 km). Air Force Station Agra is 12 km away from this property. It
        is built in the heart of the city where you can travel and explore the
        different culture of one of the oldest areas of the city.
      </p>
      <div class="image-list">
        <div class="image-container mt-2" v-for="i in 23" :key="i">
          <img :src="`/image${i + 1}.jpg`" alt="image" class="img" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        {
          id: 1,
          src: 'image1.jpg',
          price: 750,
        },
        {
          id: 2,
          src: 'image2.jpg',
          price: 750,
        },
        {
          id: 3,
          src: 'image3.jpg',
          price: 750,
        },
        {
          id: 4,
          src: 'image4.jpg',
          price: 750,
        },
        {
          id: 5,
          src: 'image5.jpg',
          price: 750,
        },
        {
          id: 6,
          src: 'image6.jpg',
          price: 750,
        },
        {
          id: 7,
          src: 'image7.jpg',
          price: 750,
        },
      ],
    };
  },
};
</script>

<style scoped>
#roomlist {
  background: #f5f5f5;
}
.content {
  max-width: 1000px;
  margin: auto;
}
.img {
  max-width: 100%;
  height: auto;
}
.section-header {
  font-size: 35px;
  font-weight: 300;
  font-style: italic;
}
</style>
