<template>
  <v-app>
    <Navbar />
    <MobileNavbar v-if="showMobileNavbar" />
    <Offer v-if="showOffer" @close="handleClose" />
    <v-main>
      <router-view class="router-view" />
      <div class="footer-body py-2 mt-5">
        <Footer />
        <p class="text-center ars-footer">All Rights Reserved.</p>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import Navbar from '@/components/UI/Navbar.vue';
import MobileNavbar from '@/components/MobileNavbar.vue';
import Offer from '@/components/Offers/Offer.vue';
import Footer from '@/components/Footer/Footer.vue';
export default {
  name: 'App',
  components: { Navbar, MobileNavbar, Offer, Footer },
  data() {
    return {
      showOffer: false,
    };
  },
  methods: {
    reRoute(param) {
      this.$router.push(`/${param}`);
    },
    handleClose() {
      this.showOffer = false;
    },
  },
  async created() {
    await axios.post(`${process.env.VUE_APP_PROD_BASE_URL}/visit`);
    const offer = sessionStorage.getItem('offer_popup');
    if (!offer) {
      sessionStorage.setItem('offer_popup', 'advertised');
      this.showOffer = true;
    } else {
      this.showOffer = false;
    }
  },

  computed: {
    ...mapGetters(['showMobileNavbar']),
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap');
* {
  font-family: 'Nunito', sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #563610;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.footer-body {
  width: 100%;
  background-color: #563610;
  color: white;
}
.visitor-count {
  color: #666;
  font-weight: bold;
  font-size: 14px;
}
.quick-links {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.quick-links span {
  margin: 10px;
  cursor: pointer;
}
.router-view {
  min-height: 70vh;
}
.ars-footer {
  text-transform: uppercase;
  letter-spacing: 1px;
}
@media (max-width: 600px) {
  .quick-links span {
    margin: 5px;
  }
}
</style>
