<template>
  <div class="nearby-body">
    <h1 class="text-center display-1 my-5">Nearby Attraction</h1>
    <div class="places ">
      <div class="place elevation-2">
        <img src="../assets/nearby/tajmahal.jpg" alt="" class="place-img">
        <div class="px-4 pt-4">
          <p class="title text-center">Taj Mahal</p>
          <p class="description pb-5">A white marble tomb built in 1631 - 48 in Agra, seat of the Mughal Empire, by Shah Jahan for his wife, Arjuman Banu Begum, the monument sums up many of the formal themes that have played through Islamic architecture. Its refined elegance is a conspicuous contrast both to the Hindu architecture of pre-Islamic India.</p>
          <div class="location">
            <v-icon>mdi-map-marker</v-icon>
            3Km from Hotel
          </div>
        </div>   
      </div>

      <div class="place elevation-2">
        <img src="../assets/nearby/agrafort.jpg" alt="" class="place-img">
        <div class="px-4 pt-4">
          <p class="title text-center">Agra Fort</p>
          <p class="description pb-5">The Agra Fort presents a formidable appearance with its vast walls, standing seventy feet in height, and a mile and a half in circuit, with imposing turrets and gateways. The outer wall is surrounded by a moat. The principal entrance to the Agra fort is the Delhi Gate.Close to Jahangiri Mahal.</p>
          <div class="location">
            <v-icon>mdi-map-marker</v-icon>
            1.5Km from Hotel
          </div>
        </div>   
      </div>

      <div class="place elevation-2">
        <img src="../assets/nearby/akbartomb.jpg" alt="" class="place-img">
        <div class="px-4 pt-4">
          <p class="title text-center">Akbar Tomb</p>
          <p class="description pb-5">Akbars Tomb at Sikandra is by far the most unique among the sepulchres of Asia. The tomb is situated on the Agra-Mathura road on National Highway No. 2. Sikandar Lodhi, the powerful king of Lodi dynasty, made Sikandra his capital in 1492 A.D. and inhabited this township. Though all the glorious signs of the then Sikandra</p>
          <div class="location">
            <v-icon>mdi-map-marker</v-icon>
            11Km from Hotel
          </div>
        </div>   
      </div>

      <div class="place elevation-2">
        <img src="../assets/nearby/soamibagh.jpg" alt="" class="place-img">
        <div class="px-4 pt-4">
          <p class="title text-center">Soami Bagh</p>
          <p class="description pb-5">Soami Bagh Samadh, Agra is one of the significant tourist spots of the city that attracts tourists from all over the world. Soami Bagh Samadh is the mausoleum of Huzur, who was the initiator of the Radhasoami Faith. Soami Bagh Samadh in Agra .</p>
          <div class="location">
            <v-icon>mdi-map-marker</v-icon>
            8km from Hotel
          </div>
        </div>   
      </div>

      <div class="place elevation-2">
        <img src="../assets/nearby//itmad.jpg" alt="" class="place-img">
        <div class="px-4 pt-4">
          <p class="title text-center">Itmad-ud-Daula</p>
          <p class="description pb-5">Itmad-ud-daula has a special place in the chronicles of both history as well as architecture. This is precisely because Itmad ud Daula is the very first tomb in India that is entirely made out of Marble.</p>
          <div class="location">
            <v-icon>mdi-map-marker</v-icon>
            8Km from Hotel
          </div>
        </div>   
      </div>

      <div class="place elevation-2">
        <img src="../assets/nearby/fatehpursikri.jpg" alt="" class="place-img">
        <div class="px-4 pt-4">
          <p class="title text-center">Fatehpur Sikri</p>
          <p class="description pb-5">Sikri was the first planned city of the Mughals. The sloping levels of the city were connected into terraces which were utilised for various complexes such as Jami masjid, Buland-Darwazah and tomb of Sheikh Salim Chishti; Khass Mahal, Shahi-Bazar, Mina-Bazar and the Panch-Mahal .</p>
          <div class="location">
            <v-icon>mdi-map-marker</v-icon>
            45Km from Hotel
          </div>
        </div>   
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      places: [  
      ]
    }
  }
}
</script>

<style scoped>
.places{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}
.place{
  padding: 5px; 
  position: relative;
  border-radius: 5px;
  cursor: pointer;
}
.place:hover{
  background-color: #eee;
  transform: scale(1.05);
  transition: all 0.6s ease;
}
.place-img{
  width: 100%;
  max-height: 220px;
}
.location{
  position: absolute;
  bottom: 10px;
  left: 0;
  text-align: center; 
  width: 100%;
}
p.description{
  font-size: 14px;
  font-style: italic;
}

@media(max-width: 900px){
  .places{ 
    grid-template-columns: repeat(2, 1fr); 
  }
}

@media(max-width: 600px){
  .places{ 
    grid-template-columns: 100%;
  }
  .place-img{
    min-height: 175px;
  }
}
</style>