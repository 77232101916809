import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Policies from '../views/QuickLinks/Policies.vue';
import Offers from '../views/QuickLinks/Offers.vue';
import PrivacyPolicy from '../views/QuickLinks/PrivacyPolicy.vue';
import AboutUs from '../views/QuickLinks/AboutUs.vue';
import Terms from '../views/QuickLinks/Terms&Conditions.vue';
import RoomList from '../views/RoomList.vue';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/rooms',
    name: 'Rooms',
    component: RoomList,
  },
  {
    path: '/policies',
    name: 'Policies',
    component: Policies,
  },
  {
    path: '/offers',
    name: 'Offers',
    component: Offers,
  },
  {
    path: '/policy/cancellation',
    name: 'CancellationPolicy',
    component: () => import('../views/QuickLinks/CancellationPolicy.vue'),
  },
  {
    path: '/policy/privacy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  {
    path: '/about',
    name: 'AboutUs',
    component: AboutUs,
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
  },
  {
    path: '/book',
    name: 'Book',
    component: () => import('../views/Bookings/Book.vue'),
  },
  {
    path: '/booking/:bookingId',
    name: 'BookingConfirmation',
    params: true,
    component: () => import('../components/Booking/Confirmation.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
