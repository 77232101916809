const state = {
  showMobileNavbar: false,
};

const actions = {
  showMobileNavbar({ commit }, value) {
    commit('SHOW_MOBILE_NAVBAR', value);
  },
};

const mutations = {
  SHOW_MOBILE_NAVBAR(state, value) {
    state.showMobileNavbar = value;
  },
};

const getters = {
  showMobileNavbar(state) {
    return state.showMobileNavbar;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
