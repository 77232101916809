<template>
  <div class="navbar-body">
    <div class="navbar-content">
      <div>
        <img src="../../assets/logo.jpg" alt="" class="branding" />
      </div>
      <div class="open-mobile-nav">
        <v-icon color="white" @click="setMobileNavbar(true)">mdi-menu</v-icon>
      </div>
      <div class="d-flex align-center navbar-links justify-space-between">
        <h1 @click="navigateToHome" v-if="isUserOnRooms">Home</h1>
        <h1 @click="navigateToRooms" v-if="!isUserOnRooms">Rooms</h1>
        <h1 @click="navigateOffers">Offers</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    navigateToRooms() {
      this.$router.push("/rooms");
    },
    navigateToHome() {
      this.$router.push("/");
    },
    setMobileNavbar(value) {
      this.$store.dispatch("showMobileNavbar", value);
    },
    navigateOffers() {
      this.$router.push("/offers");
    },
  },
  computed: {
    isUserOnRooms() {
      let url = this.$route.path;
      return url.includes("/rooms");
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap");
.branding {
  max-width: 200px;
}
.brand {
  font-family: "Abril Fatface", cursive;
  font-size: 30px;
}
.navbar-body {
  background-color: #563610;
  display: flex;
  align-items: center;
  min-height: 50px;
  color: white;
}
.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: auto;
}
h1 {
  margin-right: 20px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.8px;
}
.navbar-links h1 {
  cursor: pointer;
}
.logo-image {
  max-width: 200px;
}
.open-mobile-nav {
  display: none;
  position: absolute;
  top: 30px;
  right: 20px;
}
@media (max-width: 768px) {
  .logo-image {
    max-width: 100%;
  }
  .navbar-content {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .brand {
    font-size: 14px;
  }
  .navbar-links h1 {
    display: none;
  }
  h1 {
    margin-right: 0;
  }
  .open-mobile-nav {
    display: block;
  }
}
</style>
